const isLocalStorageAvailable = () => {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

const retrieveFirstStart = () => {
    let firstStart = true;

    try {
        firstStart = !localStorage.getItem("firstStart");
    }
    catch (e) {
        console.error("can not retrieve firstStart from localStorage", e);
    }

    return firstStart
}

const storeFirstStart = () => {

    try {
        localStorage.setItem("firstStart", JSON.stringify(new Date().toLocaleDateString()));
    }
    catch (e) {
        console.error("can not store firstStart in localStorage", e);
    }
}

const retrieveVisitedApps = () => {
    let visitedApps = [];

    try {
        visitedApps = JSON.parse(localStorage.getItem("visitedApps"));
    }
    catch (e) {
        console.error("can not retrieve visitedApps from localStorage", e);
    }

    return visitedApps
}

const storeVisitedApps = (apps) => {

    try {
        localStorage.setItem("visitedApps", JSON.stringify(apps));
    }
    catch (e) {
        console.error("can not store visitedApps in localStorage", e);
    }
}

const retrieveGmailLocked = () => {
    let gmailLocked = true;

    try {
        gmailLocked = JSON.parse(localStorage.getItem("gmailLocked"));

        if (gmailLocked === null || gmailLocked === undefined)
            gmailLocked = true;
    }
    catch (e) {
        console.error("can not retrieve gmailLocked from localStorage", e);
    }

    return gmailLocked
}

const storeGmailLocked = (value) => {

    try {
        localStorage.setItem("gmailLocked", JSON.stringify(value));
    }
    catch (e) {
        console.error("can not store gmailLocked in localStorage", e);
    }
}

const retrieveGmailLogin = () => {
    let gmailLogin = true;

    try {
        gmailLogin = localStorage.getItem("gmailLogin");
    }
    catch (e) {
        console.error("can not retrieve gmailLogin from localStorage", e);
    }

    return gmailLogin
}

const storeGmailLogin = (value) => {

    try {
        localStorage.setItem("gmailLogin", value);
    }
    catch (e) {
        console.error("can not store gmailLogin in localStorage", e);
    }
}

const retrieveChromeLocked = () => {
    let chromeLocked = true;

    try {
        chromeLocked = JSON.parse(localStorage.getItem("chromeLocked"));

        if (chromeLocked === null || chromeLocked === undefined)
            chromeLocked = true;
    }
    catch (e) {
        console.error("can not retrieve chromeLocked from localStorage", e);
    }

    return chromeLocked
}

const storeChromeLocked = (value) => {

    try {
        localStorage.setItem("chromeLocked", JSON.stringify(value));
    }
    catch (e) {
        console.error("can not store chromeLocked in localStorage", e);
    }
}

export default function createPersistencePlugin() {
    if (!isLocalStorageAvailable())
        return () => { };

    return (store) => {
        const firstStart = retrieveFirstStart();

        if (!firstStart) store.commit("unsetFirstStart");

        const visitedApps = retrieveVisitedApps();

        if (visitedApps)
            store.commit("setVisitedApps", visitedApps);

        const gmailLocked = retrieveGmailLocked();

        if (!gmailLocked)
            store.commit("unlockGmail");

        const gmailLogin = retrieveGmailLogin();

        if (gmailLogin && gmailLogin !== "")
            store.commit("setGmailLogin", gmailLogin);

        const chromeLocked = retrieveChromeLocked();

        if (!chromeLocked)
            store.commit("unlockChrome");

        store.subscribe((mutation, state) => {
            if (mutation.type === "unsetFirstStart") {
                storeFirstStart();
            } else if (mutation.type === "visitApp") {
                storeVisitedApps(state.visitedApps);
            } else if (mutation.type === "unlockGmail") {
                storeGmailLocked(false);
            } else if (mutation.type === "setGmailLogin") {
                storeGmailLogin(mutation.payload);
            } else if (mutation.type === "unlockChrome") {
                storeChromeLocked(false);
            }
        });
    }
}
<template>
  <div class="app-window">
    <!-- <BackButton class="app-back-button" @click="requestClose"></BackButton> -->
    <app-notification
      class="app-notification"
      v-if="notification"
      @close="hideNotification"
    >
      <template #title> Google Sheets </template>
      <template #default>
        Le C.E.C semble avoir laissé une liste de questions sur ce fichier. Mais
        les réponses semblent être codées...<br />
        La clé de déchiffrage se cache sûrement dans une autre application.
      </template>
    </app-notification>
    <div class="sheet-toolbars">
      <div class="app-logo"></div>
      <template>
        <div class="first-menu">
          <div class="app-header">
            <div class="app-title">GOOGLE SHEETS</div>
            <v-icon small>mdi-star-outline</v-icon>
            <v-icon small>mdi-folder-move-outline</v-icon>
            <v-icon small>mdi-cloud-check-outline</v-icon>
          </div>
          <vue-file-toolbar-menu
            v-for="(content, index) in bars_content"
            :key="'bar-' + index"
            :content="content"
            class="sheet-menu"
          />
        </div>

        <div class="second-menu">
          <vue-file-toolbar-menu
            v-for="(content, index) in bars_content2"
            :key="'bar-' + index"
            :content="content"
            class="sheet-menu"
          />
        </div>
      </template>
    </div>
    <div class="sheet-container">
      <ve-table
        class="sheet"
        :columns="columns"
        :table-data="fullTableData"
        max-height="100%"
        fixed-header
        border-y
        border-x
        :row-style-option="rowStyleOption"
        :cell-style-option="cellStyleOption"
        :cell-autofill-option="cellAutofillOption"
        :edit-option="editOption"
      />
    </div>
    <div class="tab-container">
      <img src="../assets/gsheets_tabs.png" height="100%" />
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
import AppNotification from "./AppNotification.vue";
import VueFileToolbarMenu from "vue-file-toolbar-menu";

export default {
  name: "SheetApp",
  components: { AppNotification, VueFileToolbarMenu },
  mixins: [googleAppMixin],
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
  },
  data() {
    return {
      rowCount: 50,
      startRowIndex: 0,
      virtualScrollOption: {
        enable: true,
        scrolling: this.scrolling,
      },
      tableData: [
        {
          A: "Mes préférés 😁",
          B: "Mot codé 🔑",
        },
        {
          A: "Sport",
          B: "Sdmmhr",
        },
        {
          A: "Ordinateur",
          B: "Bgqnldannj",
        },
        {
          A: "Pays",
          B: "Eqzmbd",
        },
        {
          A: "C.E.C.",
          B: "Bzlotr",
        },
        {
          A: "Livre",
          B: "Rgdqknbj",
        },
        {
          A: "Animal",
          B: "Bgzs",
        },
        {
          A: "Streamer",
          B: "Vzmjhk",
        },
        {
          A: "Téléphone",
          B: "Ohwdk",
        },
      ],
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: false,
      },
      cellStyleOption: {
        headerCellClass: () => {
          return "table-header-class";
        },
        bodyCellClass: ({ column, rowIndex }) => {
          if (column.field === "index" && rowIndex < this.tableData.length) {
            return "table-fixed-data-column";
          } else if (["A", "B"].includes(column.field)) {
            if (rowIndex === 0) return "table-header-cell-class";
            else if (rowIndex < this.tableData.length)
              return "table-data-cell-class";
          }
        },
      },
      cellAutofillOption: {
        directionX: true,
        directionY: true,
      },
      editOption: {},
      sheets: [{ name: "A=B" }],
      notificationDismissed: false,
    };
  },
  computed: {
    colCount: function () {
      return this.isMobile ? 2 : 3;
    },
    colWidth: function () {
      return this.isMobile ? "50%" : "40%";
    },
    subbar_content1() {
      return [
        {
          html: "<strong class='sheets-app-title'>GOOGLE SHEETS</strong>",
        },
        {
          icon: "star_outline",
          class: "toolbar-icon-small",
        },
        {
          icon: "drive_file_move_outline",
          class: "toolbar-icon-small",
        },
        {
          icon: "cloud_done_outline",
          class: "toolbar-icon-small",
        },
      ];
    },
    subbar_content2() {
      return [
        {
          text: "Fichier",
          mobile: true,
        },
        {
          text: "Édition",
          mobile: true,
        },
        {
          text: "Affichage",
          mobile: true,
        },
        {
          text: "Insertion",
          mobile: true,
        },
        {
          text: "Format",
          mobile: true,
        },
        {
          text: "Données",
        },
        {
          text: "Outils",
        },
        {
          text: "Extensions",
        },
        {
          text: "Aide",
          mobile: true,
        },
        { is: "spacer" },
        {
          icon: this.edit_mode ? "lock_open" : "lock",
          title: "Toggle edit mode",
          active: !this.edit_mode,
          click: () => {
            this.edit_mode = !this.edit_mode;
          },
        },
      ];
    },
    bars_content() {
      const content2 =
        this.isMobile && this.isPortrait
          ? this.subbar_content2.filter((c) => c.mobile)
          : this.subbar_content2;
      return [content2];
    },
    bars_content2() {
      const bar = [
        {
          icon: "undo",
          class: "sheet-toolbar-button",
          mobile: true,
        },
        {
          icon: "redo",
          mobile: true,
        },
        {
          icon: "printer",
          mobile: true,
        },
        {
          icon: "format_paint",
        },
        { is: "separator" },
        {
          text: "100%",
          type: "compact",
          chevron: true,
          mobile: true,
        },
        { is: "separator", mobile: true },
        {
          icon: "euro",
          mobile: true,
          class: "toolbar-icon-small",
        },
        {
          icon: "percent_outline",
          mobile: true,
          class: "toolbar-icon-small",
        },
        {
          text: "123",
          chevron: true,
        },
        { is: "separator", mobile: true },
        {
          text: "Arial",
          chevron: true,
        },
        { is: "separator" },
        {
          text: "10",
          chevron: true,
        },
        { is: "separator" },
        {
          icon: "format_bold",
          mobile: true,
        },
        {
          icon: "format_italic",
          mobile: true,
        },
        {
          icon: "format_strikethrough",
          mobile: true,
        },
        { is: "separator", mobile: true },
        {
          icon: "format_list_numbered",
          mobile: true,
        },
        {
          icon: "format_list_bulleted",
          mobile: true,
        },
        { is: "separator" },
        {
          icon: "format_indent_increase",
        },
        {
          icon: "format_indent_decrease",
        },
      ];

      if (this.isMobile && this.isPortrait)
        return [bar.filter((item) => item.mobile)];

      return [bar];
    },
    alphabet: function () {
      // const alpha = Array.from(Array(this.colCount)).map((e, i) => i + 65);
      // return alpha.map((x) => String.fromCharCode(x));
      return ["A", "B", " "];
    },
    columns() {
      let columns = [
        {
          field: "index",
          key: "index",
          // is operation column
          operationColumn: true,
          title: "",
          width: 55,
          fixed: "left",
          renderBodyCell: this.renderRowIndex,
        },
      ];
      columns = columns.concat(
        this.alphabet.map((keyValue) => {
          return {
            title: keyValue,
            field: keyValue,
            key: keyValue,
            width: ["A", "B"].includes(keyValue) ? this.colWidth : "auto",
            edit: true,
          };
        })
      );
      return columns;
    },
    fullTableData: function () {
      return this.tableData.concat(
        new Array(this.rowCount - this.tableData.length).fill({ A: "", B: "" })
      );
    },
  },
  methods: {
    // render row index
    renderRowIndex({ rowIndex }) {
      return <span>{rowIndex + this.startRowIndex + 1}</span>;
    },
  },
};
</script>
<style>
:root {
  --table-border-style: 1px solid black;
}

.sheets-app-title {
  padding: 3% 0;
}

.table-header-class {
  padding: 1% !important;
}

.table-fixed-data-column {
  border-right: var(--table-border-style) !important;
}

.table-header-cell-class {
  background: #868686 !important;
  color: #000 !important;
  font-weight: bold;
  border: var(--table-border-style) !important;
  font-size: 1.5em !important;
}

.table-data-cell-class {
  border: 1px solid black;
  border: var(--table-border-style) !important;
  font-size: 1.5em !important;
}

.mobile .table-data-cell-class {
  font-size: 1.8em !important;
}

.table-header-cell-class {
  font-size: 1.8em !important;
}

.toolbar-icon-small {
  font-size: 0.8em;
}
</style>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.app-header {
  display: flex;
  align-items: center;
  gap: 2%;
  padding-left: 3px; /* pour composer padding menu du dessous */
}

.app-title {
  font-weight: 500;
  font-size: 1.6em;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  max-width: 40%;
  z-index: 30;
}

.mobile .app-notification {
  max-width: 70%;
}

.mobile.portrait .app-notification {
  max-width: 70%;
}

.sheet-toolbars {
  flex-grow: 0;
  display: grid;
  grid-template-columns: 7% 1fr;
  --bar-button-icon-size: 1.8em;
}

.sheet-toolbars > .app-logo {
  grid-column: 1;
  grid-row: 1;
  background-image: url(../assets/sheets_icon.png);
  background-size: 55%;
  background-position-x: center;
  background-position-y: 25%;
}

.mobile .sheet-toolbars > .app-logo {
  background-size: 70%;
  background-position: center;
}

.mobile.portrait .sheet-toolbars > .app-logo {
  background-size: contain;
}

.sheet-toolbars .sheet-menu {
  font-size: 1.5em;
}

.sheet-toolbars > .first-menu {
  grid-column: 2;
  grid-row: 1;
}

.sheet-toolbars > .second-menu {
  grid-column: 1 / 3;
  grid-row: 2;
  padding-left: 3%;
}

.sheet-toolbars > div {
  border-bottom: 1px solid #eee;
}

.sheet-container {
  flex-grow: 1;
  min-height: 0; /* trick to prevent overflow (https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container) */
}

.sheet {
  height: 100%;
}

.sheet >>> table {
  border-collapse: collapse !important;
}

.tab-container {
  /* display: flex;
  gap: 3%; */
  height: 7%;
  font-size: 1.8em;
}

.mobile .tab-container {
  height: 10%;
}

.mobile.portrait .tab-container {
  height: 6%;
}

.tab-container > div {
  box-shadow: 0 1px 3px 1px rgb(60 64 67 / 15%);
  padding: 1%;
  font-weight: 500;
  display: flex;
  align-items: center;
}
</style>

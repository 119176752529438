<template>
  <v-app class="embedded-app">
    <Chromebook></Chromebook>
  </v-app>
</template>
<script>
import Chromebook from "./components/Chromebook.vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Chromebook,
  },
};
</script>
<style>
@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "SF UI Display";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url(./fonts/sf-ui-display-thin.woff) format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/ProductSans-Medium.woff) format("woff");
}

.embedded-app {
  max-height: 100%;
}
</style>

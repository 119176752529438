<template>
  <div class="app-window" :class="{ mobile: isMobile, portrait: isPortrait }">
    <!-- <BackButton class="app-back-button" @click="requestClose"></BackButton> -->
    <AppNotification
      class="app-notification"
      v-if="notification"
      @close="hideNotification"
    >
      <template #title>Google Keep</template>
      <template #default>
        Des notes ont été laissées dans Google Keep. Pratique si on a une
        mémoire de poisson rou...<br />
        HÉ regarde&nbsp;! Des notes ont été laissées dans Google Keep&nbsp;! Un
        indice se cache certainement parmis ces notes.
      </template>
    </AppNotification>
    <div class="app-toolbar">
      <img
        src="../assets/gkeep_toolbar_mobile_portrait.png"
        width="100%"
        v-if="isMobile && isPortrait"
      />
      <img src="../assets/gkeep_toolbar.svg" width="100%" v-else />
    </div>
    <div class="app-body">
      <div class="app-side-menu" v-if="!isMobile">
        <v-navigation-drawer permanent>
          <v-list dense nav shaped>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              active-class="keep-selected-menu"
              class="app-side-menu-item"
            >
              <v-list-item-content>
                <v-icon small>{{ item.icon }}</v-icon>
                <span class="app-side-menu-item-title">{{ item.title }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div class="todos-container">
        <div class="todos-title">notes épinglées</div>
        <div class="todo-card">
          <u>Règles du C.E.C.</u><br />
          1. On ne parle pas du C.E.C.<br />
          2. On ne parle pas du C.E.C. !!!<br />
          3. Pour coder un texte, on décale toujours tout d'une lettre vers la
          gauche (a=b, b=c, …).<br />
          4. On récompense toujours nos détectives
        </div>
        <div class="todo-card">
          <strong>Idées cadeaux de Noël :</strong><br />
          <br />
          Un ordinateur Chromebook<br />
          Smartphone Google Pixel 7<br />
          Un vélo<br />
          Des livres<br />
          Écouteurs Pixel Buds Pro<br />
          Un poney<br />
          Un jeu d'enquête
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
import AppNotification from "./AppNotification.vue";
// import BackButton from "./BackButton.vue";

export default {
  name: "KeepApp",
  mixins: [googleAppMixin],
  components: {
    AppNotification,
  },
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
    displayNotification: Boolean,
  },
  data() {
    return {
      items: [
        { title: "Notes", icon: "mdi-lightbulb-outline" },
        { title: "Rappels", icon: "mdi-bell-outline" },
        { title: "Archives", icon: "mdi-package-down" },
        { title: "Corbeille", icon: "mdi-trash-can-outline" },
      ],
      showPreview: false,
      previewIndex: 0,
      notificationDismissed: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  max-width: 40%;
  z-index: 20;
}

.mobile .app-notification {
  max-width: 60%;
}

.mobile.portrait .app-notification {
  max-width: 80%;
}

.app-body {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 15% 1fr;
}

.mobile .app-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
}

.app-side-menu {
  opacity: 0.6;
}

.app-side-menu-item {
  min-height: 0;
}

.app-side-menu-item-title {
  font-size: 1.5em !important;
  text-align: center;
  font-weight: 500;
}

.todos-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-template-rows: auto auto 1fr;
  padding: 8% 8% 0;
  font-family: Roboto, Arial, sans-serif;
  gap: 2%;
}

.mobile .todos-container {
  grid-template-rows: auto 1fr 1fr;
}

.mobile.portrait .todos-container {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto repeat(3, 1fr);
}

.todos-title {
  grid-column: span 3;
  font-weight: 500;
  color: #5f6368;
  text-transform: uppercase;
  font-size: 1.3em;
  margin: 4% 4% 1%;
}

.mobile.portrait .todos-title {
  grid-column: span 2;
}

.todo-card {
  border: 1px solid #fff475;
  border-radius: 8px;
  padding: 10%;
  font-size: 1.5em;
  background-color: #fff475;
  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}

.mobile .todo-card {
  font-size: 1.7em;
}

.mobile.portrait .todo-card {
  grid-column: span 2;
}
</style>

<template>
  <div class="notification-container" :class="{ mobile: isMobile }">
    <div v-if="iconified" class="notication-iconified-container">
      <v-btn icon @click="iconified = false"
        ><v-icon color="#ffffff">mdi-lightbulb-on</v-icon></v-btn
      >
    </div>

    <template v-else>
      <div class="notification-header">
        <div class="notification-icon" :style="{ 'align-self': iconAlign }">
          <slot name="icon"
            ><v-icon color="#ffffff">mdi-lightbulb-on</v-icon></slot
          >
        </div>
        <div class="notification-title"><slot name="title"></slot></div>
        <v-btn icon color="#ffffff" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="notification-text">
        <slot></slot>
      </v-card-text>
    </template>
  </div>
</template>
<script>
export default {
  name: "AppNotification",
  props: {
    autoClose: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 2000,
    },
    showNotification: {
      type: Boolean,
      default: false,
    },
    iconAlign: {
      type: String,
      default: "baseline",
    },
  },
  data() {
    return {
      expired: false,
      iconified: false,
    };
  },
  methods: {
    close: function () {
      this.expired = true;
      this.$emit("close");
    },
    iconify: function () {
      this.iconified = true;
      this.$emit("iconify");
    },
  },
  mounted() {
    if (this.autoClose) {
      setTimeout(() => {
        this.expired = true;
      }, this.delay);
    }

    // this.iconified = !this.showNotification;
  },
};
</script>
<style scoped>
.notification-container {
  display: flex;
  flex-direction: column;
  background-color: #1a73e8;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1.277124285697937px 1.277124285697937px 0px #00000040;
}

.mobile .notification-text {
  font-size: 2.2em;
}

.notification-text {
  text-align: left;
}

.notification-header {
  display: flex;
  align-items: center;
  gap: 2%;
  padding-left: 3%;
  padding-top: 1%;
}

.notification-title {
  flex: 1;
  text-align: left;
  font-size: 2em;
}
</style>

<template>
  <div class="app-window" :class="{ mobile: isMobile, portrait: isPortrait }">
    <!-- <BackButton class="app-back-button" @click="requestClose"></BackButton> -->
    <AppNotification
      class="app-notification"
      v-if="notification"
      @close="hideNotification"
    >
      <template #title>Google Chrome</template>
      <template #default>
        Le C.E.C. semble nous mettre au défi&nbsp;!<br />
        Si tu arrives à trouver le mot secret et le code, tu résoudras sûrement
        l'enquête.<br />
        Des indices doivent se cacher dans d'autres applications&nbsp;!
      </template>
    </AppNotification>
    <div class="app-toolbar">
      <img
        src="../assets/chrome_toolbar_mobile_portrait.png"
        v-if="isMobile && isPortrait"
        width="100%"
      />
      <img src="../assets/chrome_toolbar.svg" width="100%" v-else />
    </div>
    <div class="app-header">
      <img
        src="../assets/chrome_header_mobile_portrait.png"
        width="100%"
        v-if="isMobile && isPortrait"
      />
      <img src="../assets/chrome_header.svg" width="100%" v-else />
    </div>
    <div class="app-body" :class="{ 'app-unlocked': !isLocked }">
      <div class="app-body-overlay"></div>
      <template v-if="isLocked">
        <img src="../assets/chrome_padlock.png" class="chrome-padlock-bg" />
        <img src="../assets/chrome_glasses.png" class="chrome-glasses-bg" />
      </template>
      <template v-if="!isMobile">
        <img src="../assets/chrome_eyes.png" class="chrome-eyes-bg" />
        <img src="../assets/chrome_magnifier.png" class="chrome-magnifier-bg" />
        <img src="../assets/chrome_chromebook.png" class="chrome-chromebook-bg"
      /></template>

      <div class="chrome-login-overlay" v-if="isLocked">
        <div class="chrome-login-headline">
          Réussiras-tu à trouver les codes ?
        </div>
        <div class="chrome-login-fields-container">
          <div class="chrome-login-input">
            <input
              type="text"
              placeholder="Mot secret"
              v-model="secret"
              @keypress.enter="tryUnlock"
            />
          </div>
          <div class="chrome-login-input">
            <input
              type="password"
              placeholder="Code à 4 chiffres"
              v-model="pin"
              @keypress.enter="tryUnlock"
            />
          </div>
        </div>
        <div v-if="isError" class="chrome-login-error">
          Secret ou code incorrect
        </div>

        <div class="chrome-login-submit" @click="tryUnlock">envoyer</div>
      </div>
      <div class="chrome-congrats-overlay" v-else>
        <h1>🎁 Bravo, tu as réussi cette enquête !</h1>
        <br />
        <p>
          Nous avions vu juste à ton sujet. Ta perspicacité et ton instinct
          t'ont permis d'arriver au bout de cette énigme. Ce sont les qualités
          des grand·es détectives&nbsp;!<br />
          Pour revendiquer ta récompense, remplis le formulaire ci-dessous, un
          tirage au sort aura lieu prochainement pour déterminer les vainqueurs
          :<br />
        </p>
        <br />
        <div class="cta-container">
          <v-btn
            color="#FC421D"
            class="white--text"
            rounded
            @click="cta"
            :small="isMobile"
            >Obtenir ma récompense</v-btn
          >
        </div>
        <br />
        <div class="prizes-container">
          <h2>À la clé, de nombreux lots de produits Google à gagner !!</h2>
          <div>
            <strong>MEGA LOT :</strong> Pack Chromebook HP étudiant + Google
            Pixel 6a + Pixel Buds Pro
          </div>
          <div>
            <strong>2e lot :</strong> Pack Chromebook ASUS C433 + Nest Audio +
            Chromecast 4K
          </div>
          <div>
            <strong>3e lot :</strong> Pack Chromebook ASUS C433 + Nest Audio
          </div>
          <div>
            <strong>4e lot :</strong> Assistant vocal galet + Chromecast HD
          </div>
          <div><strong>5e lot :</strong> Chromecast HD</div>
        </div>
        <br />
        <div class="chrome-congrats-footnote">
          Tu as passé cette première épreuve haut la main. Mais il en faut plus
          pour intégrer définitivement le Club d'Enquête du Campus.<br />
          Tiens-toi prêt·e&nbsp;! L'enquête va continuer dans les prochains
          jours et tu auras de nouveau l'occasion d'y participer pour enfin
          faire partie du C.E.C&nbsp;!!
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
import AppNotification from "./AppNotification.vue";
// import BackButton from "./BackButton.vue";

export default {
  name: "ChromeApp",
  mixins: [googleAppMixin],
  components: {
    AppNotification,
  },
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
    displayNotification: Boolean,
  },
  data() {
    return {
      notificationDismissed: false,
      secret: "",
      pin: "",
    };
  },
  computed: {
    isLocked: function () {
      return this.$store.state.chromeLocked;
    },
    isError: function () {
      return this.$store.state.chromeError;
    },
  },
  methods: {
    tryUnlock: function () {
      this.$store.dispatch("tryUnlockChrome", {
        secret: this.secret,
        pin: this.pin,
      });
    },
    resetError: function () {
      this.$store.commit("resetChromeError");
    },
  },
  watch: {
    secret: function () {
      this.resetError();
    },
    pin: function () {
      this.resetError();
    },
  },
};
</script>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  max-width: 40%;
  z-index: 20;
}

.app-header > div {
  width: 100%;
}

.mobile .app-notification {
  max-width: 60%;
}

.mobile.portrait .app-notification {
  max-width: 80%;
}

.app-body {
  position: relative;
  flex-grow: 1;
  /* background-color: #ff6445; */
  margin-top: -8px; /* espace blanc inexpliquable */
  min-height: 0;
  overflow: auto;
}

.mobile .app-body {
  margin-top: -3px;
}

.app-body-overlay {
  width: 100%;
  height: 100%;
  /* mix-blend-mode: multiply; */
  background-image: url(../assets/chrome_orange_background.png);
  background-size: cover;
  background-position-y: 50%;
}

.app-unlocked .app-body-overlay {
  background-image: url(../assets/chrome_white_background.png);
}

.chrome-padlock-bg {
  /* background-image: url(../assets/chrome_padlock.svg); */
  position: absolute;
  left: 2%;
  top: 3%;
  height: 30%;
  /* opacity: 0.9; */
  background-size: contain;
  /* mix-blend-mode: hard-light; */
  /* background-position: left, top; */
  /* aspect-ratio: 255 / 273; */
}

.mobile .chrome-padlock-bg {
  top: unset;
  bottom: 2%;
  height: 25%;
}

.chrome-glasses-bg {
  /* background-image: url(../assets/chrome_glasses.png); */
  position: absolute;
  right: 0;
  top: 5%;
  height: 40%;
  /* opacity: 0.9; */
  /* mix-blend-mode: hard-light; */
  background-size: contain;
  /* aspect-ratio: 427 / 349; */
}

.mobile .chrome-glasses-bg {
  height: 30%;
  top: 2%;
}

.chrome-eyes-bg {
  /* background-image: url(../assets/chrome_eyes.svg); */
  position: absolute;
  left: 0%;
  bottom: 10%;
  height: 30%;
  /* opacity: 0.9; */
  /* mix-blend-mode: hard-light; */
  /* background-size: cover; */
}

.mobile .chrome-eyes-bg {
  left: unset;
  right: -12%;
  top: unset;
  bottom: 2%;
  height: 40%;
}

.app-unlocked .chrome-eyes-bg {
  /* mix-blend-mode: normal; */
  opacity: unset;
  left: unset;
  right: 0%;
  top: 0%;
}

.chrome-magnifier-bg {
  /* background-image: url(../assets/chrome_magnifier.svg); */
  position: absolute;
  right: 0%;
  bottom: 0%;
  height: 35%;
  /* opacity: 0.9; */
  /* mix-blend-mode: hard-light; */
  /* background-size: cover; */
}

.app-unlocked .chrome-magnifier-bg {
  mix-blend-mode: normal;
  opacity: unset;
}

.chrome-chromebook-bg {
  /* background-image: url(../assets/chrome_chromebook.svg); */
  position: absolute;
  left: 50%;
  bottom: 0%;
  height: 22%;
  /* opacity: 0.7; */
  /* background-size: cover; */
  transform: translate(-50%, 0);
}

.mobile .chrome-chromebook-bg {
  left: 0;
  bottom: unset;
  top: 5%;
}

.app-unlocked .chrome-chromebook-bg {
  mix-blend-mode: normal;
  opacity: unset;
  left: unset;
  bottom: unset;
  top: 45%;
  right: 5%;
  transform: translate(0, -50%);
}

.chrome-login-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  mix-blend-mode: normal;
}

.mobile .chrome-login-overlay {
  gap: 5%;
}

.chrome-login-headline {
  font-family: Roboto;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: unset;
  max-width: 55%;
}

.mobile .chrome-login-headline {
  font-size: 5em;
  max-width: 80%;
  line-height: 1.1em;
}

.chrome-login-fields-container {
  width: 100%;
  display: flex;
  gap: 5%;
  justify-content: center;
}

.mobile .chrome-login-fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20%;
  align-items: center;
}

.chrome-login-input {
  font-family: Roboto;
  font-size: 1.5em;
  font-style: italic;
  font-weight: 500;
  line-height: 2.1em;
  letter-spacing: 0em;
  text-align: left;
  width: 200px;
  height: 70%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 2% 3%;
}

.mobile .chrome-login-input {
  width: 60%;
}

.chrome-login-input input:focus,
.chrome-login-input textarea:focus {
  outline: none;
}

.chrome-login-submit {
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 2em;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0em;
  color: #fc421d;
  text-transform: uppercase;
  padding: 1.5% 3%;
  border-radius: 50px;
  box-shadow: 0px 4.81208px 4.81208px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin: 2% 0;
}

.mobile .chrome-login-submit {
  background-color: #2376e5;
  color: #ffffff;
  padding: 4% 6%;
}

.chrome-login-submit:hover {
  color: #ffffff;
  background-color: #fc421d;
}

.chrome-login-error {
  color: #ffffff;
  font-size: 2em;
}

.chrome-congrats-overlay {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10%;
  width: 90%;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column;
  align-items: flex-start;
  justify-content: center; */
  max-width: 60%;
  row-gap: 5%;
  padding: 2% 0;
}

.mobile .chrome-congrats-overlay {
  margin: 0;
  width: 100%;
  max-width: 100%;
  min-height: 0;
  overflow: overlay;
  padding: 3%;
}

.chrome-congrats-overlay > h1 {
  font-family: Roboto;
  font-size: 3.5em;
  font-weight: 900;
  line-height: 1em;
  letter-spacing: 0em;
  text-align: center;
  color: #fc421d;
}

.mobile .chrome-congrats-overlay > h1 {
  font-size: 5em;
  text-align: left;
}

.chrome-congrats-overlay p {
  font-family: Roboto;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.mobile .chrome-congrats-overlay > p {
  font-size: 1.8em;
}

.cta-container {
  text-align: center;
}

.prizes-container {
  background-color: #ffffff;
  border: 1px solid #5f6368;
  border-radius: 23px;
  padding: 2% 3%;
  display: flex;
  flex-direction: column;
  gap: 10%;
}

.mobile .prizes-container {
  padding: 10px 25px;
}

.prizes-container > h2 {
  font-family: Roboto;
  font-size: 1.8em;
  font-weight: 900;
  line-height: 1em;
  letter-spacing: 0em;
  text-align: left;
  color: #009832;
  text-decoration-line: underline;
}

.mobile .prizes-container > h2 {
  font-size: 2em;
}

.prizes-container > div {
  font-family: Roboto;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 2em;
  letter-spacing: 0em;
  text-align: left;
}

.mobile .prizes-container > div {
  font-size: 1.6em;
}

.chrome-congrats-footnote {
  font-family: Roboto;
  font-size: 1.7em;
  font-weight: 900;
  line-height: 1em;
  letter-spacing: 0em;
  text-align: left;
  color: #ff9600;
}

.mobile .chrome-congrats-footnote {
  margin-bottom: 10%;
  font-size: 2em;
}

/* .mobile .app-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
} */
</style>

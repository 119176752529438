<template>
  <div class="form-overlay">
    <!-- <BackButton class="app-back-button" @click="requestClose"></BackButton> -->
    <div class="form-container">
      <!-- <div class="form-wrapper" id="ff-compose"></div> -->
      <iframe
        src="https://formfacade.com/headless/103457987836207718393/home/form/1FAIpQLScQwoyOUS9t-lxEZSUF82xNLT4bkOEp3TUPKlnpG51nquIUqA"
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Chargement…</iframe
      >
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";

export default {
  name: "SurveyForm",
  mixins: [googleAppMixin],
  mounted() {
    // var script = document.createElement("script");
    // script.id = "ff-script";
    // script.src =
    //   "https://formfacade.com/include/103457987836207718393/form/1FAIpQLScQwoyOUS9t-lxEZSUF82xNLT4bkOEp3TUPKlnpG51nquIUqA/classic.js?div=ff-compose";
    // script.defer = true;
    // script.async = true;
    // document.body.appendChild(script);
  },
};
</script>
<style scoped>
.form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #000000aa;
  padding: 5%;
}

.app-back-button {
  position: absolute;
  z-index: 20;
  top: 10%;
  left: 7%;
}

.form-container {
  background-color: #ffffff;
  border-radius: 20px;
  height: 100%;
  padding: 5%;
  min-height: 0;
}

.form-wrapper {
  height: 100%;
  overflow: overlay;
}
</style>

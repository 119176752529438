<template>
  <div class="app-window" :class="{ mobile: isMobile, portrait: isPortrait }">
    <!-- <BackButton class="app-back-button" @click="requestClose"></BackButton> -->
    <AppNotification
      class="app-notification"
      v-if="notification"
      @close="hideNotification"
    >
      <template #title>Google Photos</template>
      <template #default>
        Il y a des milliers d'applications disponibles sur Chromebook grâce au
        PlayStore ! <br />
        Tu peux jouer, travailler, dessiner ou retoucher tes photos par exemple.
        <br />
        D'ailleurs, des indices sont peut-être cachés sur ces photos.
      </template>
    </AppNotification>
    <div v-if="showPreview" class="preview-container">
      <!-- <div class="back-container">
        <v-btn dark top left text fab @click="closePreview">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div> -->

      <v-carousel v-model="previewIndex" hide-delimiters height="100%">
        <v-carousel-item
          v-for="photo in allPhotos"
          :key="photo"
          :src="getPhotoSrc(photo)"
          contain
        >
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="app-toolbar">
      <img
        src="../assets/gphotos_toolbar_mobile_portrait.png"
        width="100%"
        v-if="isMobile && isPortrait"
      />
      <img src="../assets/gphotos_toolbar.svg" width="100%" v-else />
    </div>
    <div class="app-body">
      <div class="app-side-menu" v-if="!isMobile">
        <v-navigation-drawer permanent>
          <v-list dense nav shaped>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              class="app-side-menu-item"
            >
              <v-list-item-content>
                <v-icon small>{{ item.icon }}</v-icon>
                <span class="app-side-menu-item-title">{{ item.title }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div class="photo-gallery">
        <template v-for="(group, groupIndex) in photos">
          <div
            class="photo-date-container chapter"
            :key="`chapter-${groupIndex}`"
          >
            {{ group.chapter }}
          </div>
          <div
            class="photo-date-container subchapter"
            :key="`subchapter-${groupIndex}`"
          >
            {{ group.subchapter }}
          </div>
          <div
            v-for="(photo, index) in group.items"
            :key="`photo-${groupIndex}-${index}`"
            :style="{ 'background-image': `url(${getPhotoSrc(photo)})` }"
            class="app-photo-container"
            :class="`col${Math.floor(photosPerRow / group.items.length)}`"
            @click="preview(photo)"
          ></div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
import AppNotification from "./AppNotification.vue";

export default {
  name: "PhotosApp",
  mixins: [googleAppMixin],
  components: {
    AppNotification,
  },
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
    displayNotification: Boolean,
  },
  data() {
    return {
      items: [
        { title: "Photos", icon: "mdi-image" },
        { title: "Explorer", icon: "mdi-magnify" },
        { title: "Partage", icon: "mdi-account-multiple-outline" },
        { title: "Boutique d'impression", icon: "mdi-shopping-outline" },
      ],
      showPreview: false,
      previewIndex: 0,
      notificationDismissed: false,
    };
  },
  computed: {
    photos: function () {
      return [
        {
          chapter: "",
          subchapter: "Aujourd'hui",
          items: ["photo1.png", "photo2.jpg", "photo3.png", "photo4.png"],
        },
        {
          chapter: "décembre 2022",
          subchapter: "lun. 5 Déc. 2022",
          items: this.isPortrait ? ["photo5_portrait.png"] : ["photo5.png"],
        },
      ];
    },
    allPhotos: function () {
      const allPhotos = [];

      this.photos.forEach((group) => allPhotos.push(...group.items));
      return allPhotos;
    },
    photosPerRow: function () {
      return this.isMobile ? 2 : 4;
    },
  },
  methods: {
    back: function () {
      if (this.showPreview) this.closePreview();
      else this.requestClose();
    },
    getPhotoSrc: function (photo) {
      return require(`../assets/${photo}`);
    },
    preview: function (photo) {
      this.previewIndex = this.allPhotos.findIndex((p) => p === photo);
      this.showPreview = true;
    },
    closePreview: function () {
      this.showPreview = false;
    },
  },
};
</script>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.app-back-button {
  position: absolute;
  z-index: 20;
  bottom: 1%;
  left: 1%;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  max-width: 40%;
  z-index: 20;
}

.mobile .app-notification {
  max-width: 60%;
}

.mobile.portrait .app-notification {
  max-width: 70%;
}

.app-body {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 15% 1fr;
}

.mobile .app-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
}

.app-side-menu {
  opacity: 0.6;
}

.app-side-menu-item {
  min-height: 0;
}

.app-side-menu-item-title {
  font-size: 1.5em !important;
  text-align: center;
  font-weight: 500;
}

.photo-date-container {
  text-align: left;
  font-weight: 500;
  grid-column: span 4;
}

.photo-date-container.chapter {
  font-size: 2.5em;
}

.photo-date-container.subchapter {
  font-size: 1.6em;
}

.photo-gallery {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto 1fr auto auto 1fr;
  padding: 1%;
  font-family: Google Sans;
  color: rgb(60, 64, 67);
}

.mobile .photo-gallery {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;
  max-height: 100%;
  min-height: 0;
  overflow: overlay;
  column-gap: 5%;
}

.mobile .photo-date-container {
  grid-column: span 4;
}

.mobile.portrait .photo-date-container {
  grid-column: span 2;
}

.mobile.portrait .photo-gallery {
  grid-template-columns: repeat(2, 1fr);
}

.mobile .photo-date-container.chapter {
  font-size: 3em;
}

.mobile .photo-date-container.subchapter {
  font-size: 2em;
}

.app-photo-container {
  background-size: contain;
  background-position-y: center;
  cursor: pointer;
}

.app-photo-container.col1 {
  grid-column: span 1;
}

.app-photo-container.col2 {
  grid-column: span 2;
}

.mobile .app-photo-container.col2 {
  grid-column: span 2;
  background-size: 90%;
  background-position-y: top;
}

.app-photo-container.col4 {
  grid-column: span 4;
}

.mobile .app-photo-container.col4 {
  grid-column: span 4;
}

.mobile .app-photo-container {
  background-size: cover;
  aspect-ratio: 1 / 1;
  width: 100%;
}

.preview-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #000;
}

.back-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(50%, 50%);
  z-index: 20;
}
</style>

<template>
  <div class="tutorial-overlay">
    <video
      src="../assets/youtube_video_finale.mp4"
      class="video-player"
      autoplay
      controls
      controlsList="nodownload"
      @ended="requestClose"
      playsinline
      disablePictureInPicture
    ></video>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
export default {
  name: "TutorialApp",
  mixins: [googleAppMixin],
};
</script>
<style scoped>
.tutorial-overlay {
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  width: 100%;
}
</style>

const googleAppMixin = {
    props: {
        startupNotification: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            notification: false,
            publicPath: process.env.BASE_URL,
        }
    },
    methods: {
        back: function () {
            this.requestClose();
        },
        requestClose: function () {
            this.$emit("close");
        },
        showNotification: function () {
            this.notification = true;
        },
        hideNotification: function () {
            this.notification = false;
        },
        cta: function () {
            this.$emit("cta");
        }
    },
    created() {
        this.notification = this.startupNotification;
    }
}

export default googleAppMixin;
<template>
  <div class="app-window" :class="{ mobile: isMobile, portrait: isPortrait }">
    <AppNotification
      class="app-notification"
      v-if="notification"
      @close="hideNotification"
    >
      <template #title> Youtube </template>
      <template #default>
        Sais-tu que les Chromebooks offrent jusqu'à 12h d'autonomie&nbsp;?<br />
        En théorie, tu pourrais visionner cette vidéo 2880 fois avant que la
        batterie soit vide...
      </template>
    </AppNotification>
    <div class="app-toolbar">
      <img
        src="../assets/youtube_toolbar_mobile_portrait.png"
        v-if="isMobile && isPortrait"
        width="100%"
      />
      <img src="../assets/youtube_toolbar.svg" width="100%" v-else />
    </div>
    <div class="video-container">
      <div class="video-wrapper">
        <div>
          <video
            src="../assets/youtube_video_finale.mp4"
            class="video-player"
            ref="videoPlayer"
            @play="playing = true"
            @pause="playing = false"
            controls
            controlsList="nodownload"
            autoplay
            playsinline
            disablePictureInPicture
          ></video>
        </div>

        <div class="youtube-title-container" v-if="isMobile && isPortrait">
          Vidéo mystérieuse trouvée sur le Chromebook abandonné
        </div>
      </div>
    </div>
    <div class="youtube-comments" v-if="isMobile && isPortrait">
      <v-divider :color="dividerColor"></v-divider>
      <div class="comments-title">
        Commentaires&nbsp;<span>{{ comments.length }}</span>
      </div>
      <div
        class="youtube-comment-container"
        v-for="(comment, index) in comments"
        :key="`comment-${index}`"
      >
        <div class="youtube-comment-wrapper">
          <div class="avatar-container">
            <v-avatar :color="comment.color" size="20" class="white--text">{{
              comment.author.charAt(0)
            }}</v-avatar>
          </div>
          <div class="comment-header">
            <div class="author-container">{{ comment.author }}</div>
            <v-icon :color="foreColor">mdi-dots-vertical</v-icon>
          </div>
          <div class="comment-content" v-html="comment.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import googleAppMixin from "@/mixins/googleApp.mixin";
import AppNotification from "./AppNotification.vue";

export default {
  name: "YoutubeApp",
  components: {
    AppNotification,
  },
  mixins: [googleAppMixin],
  data() {
    return {
      playing: false,
      volume: 0,
      previousVolume: 0,
      comments: [
        {
          author: "Google",
          text: "Je parie que la personne qui trouvera à qui appartient ce Chromebook sera grandement récompensée :)",
          color: "indigo",
        },
        {
          author: "Boulanger",
          text: "Un si bel ordinateur... J'espère que quelqu'un parviendra à en retrouver le ou la propriétaire&nbsp;!<br />Sinon, rappelez-vous que les Chromebooks sont disponibles dans tous vos magasins Boulanger.",
          color: "#d14600",
        },
      ],
      notificationDismissed: false,
      dividerColor: "#3f3f3f",
    };
  },
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
    displayNotification: Boolean,
  },
  computed: {
    avatarSize: function () {
      return this.isMobile ? 28 : 36;
    },
    foreColor: function () {
      if (this.isMobile) return "#ffffff";

      return "#000000";
    },
    chipColor: function () {
      return this.isMobile ? "#272727" : "#f1f1f1";
    },
  },
  methods: {
    playVideo: function () {
      this.$refs.videoPlayer.play();
    },
    pauseVideo: function () {
      this.$refs.videoPlayer.pause();
    },
    toggleVolume: function () {
      if (this.volume === 0) {
        this.volume = this.previousVolume === 0 ? 100 : this.previousVolume;
      } else {
        this.previousVolume = this.volume;
        this.volume = 0;
      }
    },
  },
  watch: {
    volume: function (newValue) {
      this.$refs.videoPlayer.volume = newValue / 100;
    },
  },
  mounted() {
    this.volume = this.$refs.videoPlayer.volume * 100;
  },
};
</script>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.app-window.mobile.portrait {
  overflow: overlay;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  z-index: 20;
  max-width: 40%;
}

.mobile .app-notification {
  max-width: 60%;
}

.mobile.portrait .app-notification {
  max-width: 80%;
}

.app-toolbar {
  height: 9%; /* Légère marge bottom si pas de taille spécifiée */
  width: 100%;
}

.mobile .app-toolbar {
  height: 10%;
  background-color: #0f0f0f;
  margin: 0;
}

.mobile.portrait .app-toolbar {
  height: 6%;
}

.video-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
}

.video-wrapper {
  flex: 1;
  min-height: 0;
  text-align: center;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8%;
  background-image: url(../assets/youtube_video_footer.svg);
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile.portrait .video-wrapper {
  padding-bottom: 20%;
  background-image: url(../assets/youtube_video_footer_mobile_portrait.svg);
}

.video-wrapper > div {
  height: 100%;
}

.video-wrapper > div > video {
  height: 100%;
}

.mobile .video-wrapper > div::after {
  font-size: 2em;
}

.mobile .video-container {
  width: 100%;
  min-height: 0;
}

.mobile.portrait .video-container {
  flex: 0;
  min-height: auto;
}

.mobile .video-container video {
  height: 100% !important;
  width: auto !important;
}

.mobile.portrait .video-container video {
  width: 100% !important;
  height: auto !important;
}

.youtube-title-container {
  text-align: left;
  font-family: "YouTube Sans", "Roboto", sans-serif;
  font-size: 1.7em;
  font-weight: 600;
  width: 100%;
}

.mobile.portrait .youtube-comments {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 2%;
  row-gap: 1vh;
}

.mobile .youtube-comments .comments-title {
  font-weight: 500;
  font-size: 1.9em;
  text-align: left;
}

.youtube-comments .comments-title span {
  font-weight: 200;
}

.mobile.portrait .youtube-comment-container {
  margin-top: 5%;
}

.mobile.portrait .youtube-comment-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2%;
}

.mobile.portrait .youtube-comment-wrapper .avatar-container {
  grid-row: span 2;
}

.mobile.portrait .youtube-comment-wrapper .comment-header {
  display: flex;
  align-items: center;
}

.mobile.portrait .youtube-comment-wrapper .author-container {
  flex: 1;
  text-align: left;
  font-size: 1.9em;
}

.mobile.portrait .youtube-comment-wrapper .comment-content {
  text-align: left;
  font-size: 1.6em;
}
</style>

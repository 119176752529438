<template>
  <div
    class="chromebook-container"
    :class="{ mobile: isMobile, portrait: isPortrait }"
  >
    <div class="bezel-container">
      <div
        class="bezel"
        ref="bezel"
        :style="{ 'font-size': responsiveUnit + 'px' }"
      >
        <div class="screen" ref="screen">
          <div class="start-overlay" v-if="firstStart">
            <button class="start-button" @click="startGame">
              Démarrer l'enquête
            </button>
          </div>
          <FirstStartNotification
            v-if="firstStartMessage"
            @close="hideFirstStartMessage"
            :isMobile="isMobile"
            :isPortrait="isPortrait"
          ></FirstStartNotification>
          <AppNotification
            class="app-notification"
            v-if="!appDialog && notification"
            @close="notification = false"
          >
            <template #title> Chromebook </template>
            <template #default>
              Wow&nbsp;! Ce Chromebook démarre en 6 secondes seulement&nbsp;!<br />
              Il te faudra certainement plus de temps que ça pour résoudre cette
              énigme...<br />
              Navigue dans les différentes applications disponibles pour trouver
              des indices.
            </template>
          </AppNotification>
          <div
            v-if="appDialog"
            class="app-overlay"
            :class="{ nobar: openApp && openApp.noBar }"
          >
            <component
              :is="openApp.component"
              v-if="openApp"
              :isMobile="isMobile"
              :isPortrait="isPortrait"
              startupNotification
              ref="appComponent"
              @close="closeApp"
              @cta="launchSurvey"
            ></component>
          </div>
          <template v-if="isMobile">
            <div class="search-container"></div>
            <div class="apps-container">
              <div
                v-for="(app, index) in otherApps"
                :key="index"
                class="app-container"
              >
                <div
                  class="app-icon-container"
                  :class="{
                    circled: !app.rawIcon,
                    cover: !!app.cover,
                    launchable: !!app.component,
                  }"
                  :style="{
                    'background-image': `url(${getAppIconSrc(app.id)})`,
                  }"
                  @click="launchApp(app)"
                ></div>
                <div class="app-label">
                  {{ app.name }}
                </div>
              </div>
            </div>
            <div class="task-bar"></div>
          </template>
          <template v-else>
            <div class="dock-container">
              <div class="dock-search-bar">
                <div class="logo"></div>
                <div class="input">
                  Rechercher vos onglets, fichiers, applis etc.
                </div>
                <div class="bubbles"></div>
              </div>
              <div class="dock-divider-container">
                <v-divider class="dock-divider"></v-divider>
              </div>
              <div
                v-for="(app, index) in favoriteApps"
                :key="`fav-app-${index}`"
                class="app-container"
              >
                <div
                  class="app-icon-container"
                  :class="{
                    circled: !app.rawIcon,
                    cover: !!app.cover,
                    launchable: !!app.component,
                  }"
                  :style="{
                    'background-image': `url(${getAppIconSrc(app.id)})`,
                  }"
                  @click="launchApp(app)"
                ></div>
                <div class="app-label">
                  {{ app.name }}
                </div>
              </div>
              <div class="dock-divider-container short">
                <v-divider class="dock-divider"></v-divider>
              </div>
              <div
                v-for="(app, index) in otherApps"
                :key="`other-app-${index}`"
                class="app-container"
              >
                <div
                  class="app-icon-container"
                  :class="{
                    circled: !app.rawIcon,
                    cover: !!app.cover,
                    launchable: !!app.component,
                  }"
                  :style="{
                    'background-image': `url(${getAppIconSrc(app.id)})`,
                  }"
                  @click="launchApp(app)"
                ></div>
                <div class="app-label">
                  {{ app.name }}
                </div>
              </div>
            </div>
            <div class="task-bar"></div>
          </template>
          <div class="app-commands">
            <div>
              <v-btn
                fab
                color="#1a73e8"
                @click="toggleMusicVolume"
                :small="!isMobile"
                :x-small="isMobile"
                ><v-icon color="#ffffff" v-if="musicVolume === 0"
                  >mdi-volume-mute</v-icon
                >
                <v-icon color="#ffffff" v-else>mdi-volume-high</v-icon></v-btn
              >
            </div>
            <div>
              <v-btn
                fab
                :small="!isMobile"
                :x-small="isMobile"
                color="#1a73e8"
                @click="sendBack"
                v-if="openApp"
                ><v-icon color="#ffffff">mdi-arrow-left</v-icon></v-btn
              >
            </div>
            <div>
              <v-btn fab :small="!isMobile" :x-small="isMobile" color="#1a73e8"
                ><v-icon color="#ffffff" @click="showNotification"
                  >mdi-lightbulb-on</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
        <v-btn
          icon
          fab
          class="expand-button"
          @click="toggleMobileActions"
          small
          v-if="false"
          ><v-icon color="#ffffff" v-if="showMobileActions"
            >mdi-chevron-up</v-icon
          >
          <v-icon color="#ffffff" v-else>mdi-chevron-down</v-icon></v-btn
        >
      </div>
    </div>
    <div class="app-footer" v-if="showActions">
      <div class="app-footer-title">Quelle piste voulez-vous explorer ?</div>
      <div class="app-actions-container">
        <v-btn
          fab
          small
          v-for="(app, index) in ctaApps"
          @click="launchApp(app)"
          :key="`appbutton-${index}`"
          :style="{ 'background-image': `url(${getAppIconSrc(app.id)}` }"
          class="app-action-button"
          :class="{ cover: !!app.cover }"
          :disabled="firstStart"
        ></v-btn>
        <v-btn shaped small @click="closeApp" :disabled="firstStart">
          <v-icon>mdi-arrow-u-left-top</v-icon> <span>Home</span></v-btn
        >
      </div>
    </div>
    <audio
      src="../assets/background_music.mp3"
      loop
      ref="audioPlayer"
      :autoplay="autoplayMusic"
    ></audio>
  </div>
</template>
<script>
import FirstStartNotification from "./FirstStartNotification.vue";
import TutorialApp from "./TutorialApp.vue";
import SheetsApp from "./SheetsApp.vue";
import YoutubeApp from "./YoutubeApp.vue";
import PhotosApp from "./PhotosApp.vue";
// import GmailApp from "./GmailApp.vue";
import KeepApp from "./KeepApp.vue";
import ChromeApp from "./ChromeApp.vue";
import SurveyForm from "./SurveyForm.vue";
import HelpApp from "./HelpApp.vue";
import AppNotification from "./AppNotification.vue";
import appList from "../data/apps.json";
import BackButton from "./BackButton.vue";

export default {
  name: "ChromeBook",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FirstStartNotification,
    TutorialApp,
    SheetsApp,
    YoutubeApp,
    PhotosApp,
    ChromeApp,
    KeepApp,
    SurveyForm,
    HelpApp,
    AppNotification,
    BackButton,
  },
  data() {
    return {
      forceMobile: false,
      forcePortrait: false,
      showMobileActions: true,
      autoplayMusic: false,
      musicVolume: 1,
      notification: false,
      apps: appList.map((a) => {
        return { ...a, visited: this.isAppVisited(a) };
      }),
      appDialog: false,
      openApp: null,
      startAppId: "tutorial",
      screenSize: {
        width: 0,
        height: 0,
      },
      firstStartMessage: false,
    };
  },
  computed: {
    firstStart: {
      get: function () {
        return this.$store.state.firstStart;
      },
      set: function (newValue) {
        if (!newValue) this.$store.commit("unsetFirstStart");
      },
    },
    isMobile: function () {
      if (this.forceMobile) return true;

      return this.$vuetify.breakpoint.smAndDown;
    },
    isPortrait: function () {
      if (this.forcePortrait) return true;

      return this.$vuetify.breakpoint.width <= this.$vuetify.breakpoint.height;
    },
    aspectRatio: function () {
      if (this.isMobile) {
        if (this.isPortrait) return 825 / 1269;
        else return 1269 / 825;
      } else return 1687 / 1227;
    },
    responsiveUnit: function () {
      return this.isPortrait
        ? this.screenSize.height / 100
        : this.screenSize.width / 100;
    },
    showActions: function () {
      if (this.isMobile) return this.showMobileActions;

      return true;
    },
    visibleApps: function () {
      return this.apps.filter((a) => !a.hidden);
    },
    favoriteApps: function () {
      return this.visibleApps
        .filter((a) => a.favorite)
        .sort((app1, app2) => app1.favoriteIndex - app2.favoriteIndex);
    },
    launchableApps: function () {
      return this.visibleApps.filter((a) => !!a.component);
    },
    ctaApps: function () {
      return this.launchableApps.filter((a) => !!a.cta);
    },
    otherApps: function () {
      return this.visibleApps
        .filter((app) => !app.favorite)
        .sort((app1, app2) => app1.dockIndex - app2.dockIndex);
    },
    colsPerApp: function () {
      return this.isPortrait ? 3 : 2;
    },
    startApp: function () {
      return this.apps.find((a) => a.id === this.startAppId);
    },
    surveyApp: function () {
      return this.apps.find((a) => a.id === "survey"); //TODO: pas de nom d'appli en dur
    },
  },
  methods: {
    startGame: function () {
      this.firstStart = false;
      this.notification = true;
      // this.launchApp(this.startApp);
      this.launchTutorial();
    },
    showNotification: function () {
      if (this.openApp) this.$refs.appComponent.showNotification();
      else this.notification = true;
    },
    toggleMobileActions: function () {
      this.showMobileActions = !this.showMobileActions;
    },
    playMusic: function () {
      this.$refs.audioPlayer.play();
    },
    pauseMusic: function () {
      this.$refs.audioPlayer.pause();
    },
    toggleMusicVolume: function () {
      this.musicVolume = this.musicVolume === 0 ? 1 : 0;
    },
    isAppVisited: function (app) {
      return this.$store.state.visitedApps.includes(app.id);
    },
    visitApp: function (app) {
      this.$store.commit("visitApp", app.id);
    },
    getAppIconSrc: function (app) {
      return require(`../assets/${app}_icon.png`);
    },
    launchApp(app) {
      if (!app.component) return;
      if (this.openApp) this.closeApp();

      this.openApp = app;
      this.appDialog = true;
    },
    sendBack: function () {
      this.$refs.appComponent.back();
    },
    closeApp() {
      this.appDialog = false;
      this.openApp = null;
    },
    launchTutorial: function () {
      this.launchApp(this.startApp);
    },
    launchSurvey: function () {
      console.log("launch survey");
      this.launchApp(this.surveyApp);
    },
    showFirstStartMessage: function () {
      this.firstStartMessage = true;
    },
    hideFirstStartMessage: function () {
      this.firstStartMessage = false;
    },
  },
  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data === undefined || event.data === "undefined") return;

        if ("mobile" in event.data) this.forceMobile = event.data.mobile;

        if ("portrait" in event.data) this.forcePortrait = event.data.portrait;
      },
      false
    );

    document.addEventListener(
      "visibilitychange",
      () => {
        console.log(document.visibilityState);
        if (document.visibilityState === "hidden") this.pauseMusic();
        else if (!this.openApp || !this.openApp.noMusic) this.playMusic();
      },
      false
    );

    //this.retrieveFirstStart();
    this.autoplayMusic = !this.firstStart;

    if (!this.firstStart) {
      this.showNotification();
      this.playMusic();
    }

    console.log("dimensions fenêtre", window.innerWidth, window.innerHeight);

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize;

        this.screenSize.width = contentBoxSize.inlineSize;
        this.screenSize.height = contentBoxSize.blockSize;
      });
    });

    resizeObserver.observe(this.$refs.screen);
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("mobile")) this.forceMobile = true;

    if (urlParams.has("portrait")) this.forcePortrait = true;
  },
  unmounted() {
    this.pauseMusic();
  },
  watch: {
    musicVolume: function (newValue) {
      const audioPlayer = this.$refs.audioPlayer;

      audioPlayer.volume = newValue;
      audioPlayer.muted = newValue === 0;
    },
    openApp: function (app, previousApp) {
      if (app) {
        if (app.noMusic) this.pauseMusic();
        else this.playMusic();

        this.visitApp(app);
      } else {
        this.showNotification();
        this.playMusic();
      }

      if (previousApp) previousApp.visited = true;

      if (previousApp === this.startApp) this.showFirstStartMessage();
    },
  },
};
</script>
<style scoped>
.chromebook-container {
  width: 100%;
  height: 100%;
  /* display: table; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100vh;
  min-height: 0;
}

.bezel-container {
  /* flex: 1; */
  min-height: 0;
}

.bezel {
  position: relative;
  background-image: url(../assets/chromebook_desktop.png);
  background-size: contain;
  background-position: center;
  margin: auto;
  aspect-ratio: 2085.62 / 1370.14;
  min-height: 0;
  max-height: 100vh;
  height: 100%;
  /* border: 1px dashed black; */
}

.mobile .bezel {
  background-image: url(../assets/chromebook_tablet_landscape.png);
  aspect-ratio: 714 / 461;
}

.mobile.portrait .bezel {
  background-image: url(../assets/chromebook_tablet_portrait.png);
  aspect-ratio: 461 / 714;
}

/* .volume-button {
  position: absolute;
  left: 14%;
  bottom: 14%;
  background-color: #1a73e8;
}

.mobile .volume-button {
  position: absolute;
  left: 2%;
  bottom: 2%;
} */

.app-commands {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  transform: translate(0, 100%);
  gap: 1%;
  padding-top: 1%;
  background-image: url(../assets/chromebook_logo.svg);
  background-size: 20%;
  background-position: center 85%;
}

.mobile .app-commands {
  padding: 0 1% 1%;
  z-index: 30;
  background-image: none;
}

.mobile.portrait .app-commands {
  transform: none;
  padding: 0 1% 1%;
  z-index: 30;
}

.expand-button {
  position: absolute;
  right: 12%;
  bottom: 12%;
  z-index: 20;
}

.mobile .expand-button {
  position: absolute;
  right: 0;
  bottom: 0;
}

.screen {
  /* border: 1px dashed white; */
  position: absolute;
  top: 6%;
  bottom: 21%;
  left: 14%;
  right: 13.5%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url(../assets/wallpaper_green.png);
  background-size: cover;
}

.mobile .screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 4.5%;
  right: 2%;
  bottom: 11%;
  left: 2.1%;
}

.start-overlay {
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: #282828ad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-button {
  background: linear-gradient(94.8deg, #5d5fef 3.05%, #3334c4 98.11%);
  border-radius: 18.19315528869629px;
  padding: 2%;
  box-shadow: 0px 1.277124285697937px 1.277124285697937px 0px #00000040;
  font-family: Google Sans;
  font-size: 3.2em;
  text-align: center;
  color: #ffffff;
}

.mobile.portrait .start-button {
  padding: 4%;
}

.app-notification {
  position: absolute;
  right: 2%;
  bottom: 5%;
  /* width: 30%;
  min-height: 20%; */
  max-width: 40%;
  z-index: 20;
}

.mobile .app-notification {
  max-width: 70%;
}

.mobile.portrait .app-notification {
  max-width: 70%;
}

.search-container {
  width: 100%;
  background-image: url(../assets/search_bar_mobile.svg);
  background-position: center;
}

.mobile .search-container {
  height: 20%;
  background-size: 45%;
}

.mobile.portrait .search-container {
  height: 20%;
  background-size: 66%;
}

.dock-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 58%;
  height: 81%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2.5%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 10% 1px auto 1px auto auto;
  align-items: center;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  margin-bottom: 3%;
  margin-left: 0.8%;
}

.dock-search-bar {
  grid-column: span 5;
  display: flex;
  align-content: center;
}

.dock-search-bar .logo {
  background-image: url(../assets/google_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  aspect-ratio: 1 / 1;
  width: 8%;
}

.dock-search-bar .input {
  flex: 1;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: -0.015em;
  text-align: center;
  color: #5f6368;
}

.dock-search-bar .bubbles {
  background-image: url(../assets/bubbles.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 45%;
  /* height: 100%; */
  aspect-ratio: 1 / 1;
  width: 8%;
}

.app-label {
  font-family: Roboto;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: -0.03em;
  text-align: center;
  margin-top: 10%;
  color: #202124;
}

.mobile .app-label {
  color: #ffffff;
  font-size: 1.7em;
}

/* .google-logo {
  height: 10%;
} */

.dock-divider-container {
  grid-column: span 5;
  height: 1px;
}

.dock-divider {
  border-color: #d8d8d8;
  background-color: #d8d8d8;
  color: #d8d8d8;
}

.dock-divider-container.short {
  padding-left: 5%;
  padding-right: 5%;
}

.apps-container {
  grid-area: apps;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex: 1;
  width: 100%;
}

.mobile.portrait .apps-container {
  grid-template-columns: repeat(4, 1fr);
}

.mobile.portrait .screen {
  top: 2.1%;
  right: 4.2%;
  bottom: 2.1%;
  left: 11.3%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url(../assets/wallpaper_green_portrait.png);
  background-position: center;
}

.search-wrapper {
  width: 60%;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 1% 1% 1% 5%;
  margin: auto;
  background-image: url(../assets/google-letter.svg);
  background-position-y: center;
  background-position-x: 0.5em;
  background-size: 1em;
  text-align: center;
}

.search-input {
  width: 80%;
  font-size: 0.8em;
}

.app-icon-container {
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 35%;
  margin: auto;
  border-radius: 50%;
  box-shadow: #00000033 1px 1px 2px;
  /* box-shadow: 18.909082412719727px 18.909082412719727px 37.81816482543945px 14.181814193725586px #00000033; */
  opacity: 0.3;
}

.app-icon-container.launchable {
  cursor: pointer;
  opacity: 1;
}

.app-icon-container.circled {
  background-color: white;
  padding: 2.6%;
  background-size: 70%;
}

.app-icon-container.circled.cover {
  background-size: cover;
}

.mobile.portrait .app-icon-container {
  width: 37%;
}

.mobile .app-icon-container {
  width: 30%;
}

.app-overlay {
  position: absolute;
  width: 100%;
  height: 96.3%; /* taskbar */
  z-index: 10;
}

.app-overlay.nobar {
  height: 100%;
}

.mobile .app-overlay {
  height: 100%; /* taskbar */
}

.task-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.7%;
  background-image: url(../assets/taskbar.svg);
  background-size: cover;
}

.mobile.portrait .task-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4%;
  background-image: url(../assets/taskbar_mobile_portrait.png);
  background-size: contain;
}

.mobile .task-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.2%;
  background-image: none;
}

.app-footer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.app-footer-title {
  text-align: center;
  /* margin-top: 1%; */
  font-family: Google Sans;
  font-size: 3vw;
  font-weight: 300;
  letter-spacing: -0.04em;
  text-align: center;
}

.mobile.portrait .app-footer-title {
  font-size: 3vh;
}

.app-actions-container {
  /* height: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1%;
  gap: 1%;
}

.app-action-button {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.app-action-button.cover {
  background-size: cover;
}
</style>

<template>
  <div class="app-window">
    <AppNotification
      class="app-notification"
      showNotification
      @close="requestClose"
      iconAlign="center"
    >
      <template #icon>
        <v-icon color="#ffffff">mdi-alert</v-icon>
      </template>
      <template #title
        ><strong>A consulter uniquement si tu es bloqué.e</strong></template
      >
      <template #default>
        <v-expansion-panels accordion flat tile>
          <v-expansion-panel
            v-for="(clue, index) in clues"
            :key="index"
            color="#1a73e"
            class="app-expansion-panel"
          >
            <v-expansion-panel-header
              color="#1a73e8"
              class="app-expansion-panel-text"
              ><template #default>{{ clue.title }}</template>
              <template #actions
                ><v-icon color="#ffffff">mdi-menu-down</v-icon></template
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content
              color="#1a73e8"
              class="app-expansion-panel-text"
            >
              <div v-html="clue.content"></div>
            </v-expansion-panel-content>
          </v-expansion-panel> </v-expansion-panels
      ></template>
    </AppNotification>
  </div>
</template>
<script>
import AppNotification from "./AppNotification.vue";
import googleAppMixin from "@/mixins/googleApp.mixin";

export default {
  name: "HelpApp",
  components: {
    AppNotification,
  },
  data() {
    return {
      clues: [
        {
          title: "Indice",
          content:
            "Pour résoudre l'enquête, il faut trouver les codes de la page Google Chrome. Il faut donc retrouver un mot secret et un code à 4 chiffres. Explore toutes les applications disponibles.",
        },
        {
          title: "Réponse n° 1",
          content:
            "Sur Google Photos, l’une des images révèle le code à 4 chiffres : 2905",
        },
        {
          title: "Réponse n° 2",
          content:
            "Sur Google Sheets, des mots sont codés.<br />Sur Google Keep, il y a la clé de déchiffrage.<br />Le mot secret est celui lié à C.E.C. : campus",
        },
      ],
    };
  },
  mixins: [googleAppMixin],
};
</script>
<style scoped>
.app-window {
  width: 100%;
  height: 100%;
}

.app-notification {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.app-expansion-panel {
  background-color: #1a73e8 !important;
}

.mobile .app-notification {
  width: 90%;
}

.app-expansion-panel-text {
  color: #ffffff;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistencePlugin from "./plugins/persistencePlugin";
import { chromeAuthenticate } from "../utils";

Vue.use(Vuex)

const persistencePlugin = createPersistencePlugin();

export default new Vuex.Store({
    plugins: [persistencePlugin],
    state: {
        firstStart: true,
        visitedApps: [],
        gmailLocked: true,
        chromeLocked: true,
        chromeError: false
    },
    mutations: {
        unsetFirstStart: function (state) {
            state.firstStart = false;
        },
        setVisitedApps: function (state, payload) {
            state.visitedApps = payload;
        },
        visitApp: function (state, payload) {
            if (!state.visitedApps.includes(payload))
                state.visitedApps.push(payload);
        },
        unlockGmail: function (state) {
            state.gmailLocked = false;
        },
        setGmailLogin: function (state, payload) {
            state.gmailLogin = payload;
        },
        unlockChrome: function (state) {
            state.chromeLocked = false;
        },
        setChromeError: function (state) {
            state.chromeError = true;
        },
        resetChromeError: function (state) {
            state.chromeError = false;
        }
    },
    actions: {
        tryUnlockChrome: function ({ commit }, payload) {
            if (chromeAuthenticate(payload.secret, payload.pin)) {
                commit("resetChromeError");
                commit("unlockChrome");
            }
            else
                commit("setChromeError");
        }
    }
});
<template>
  <div
    class="first-start-overlay"
    :class="{ mobile: isMobile, portrait: isPortrait }"
  >
    <div class="first-start-container">
      <img src="../assets/cec_logo.png" class="first-start-logo" />
      <div class="first-start-title">
        Bienvenue dans le jeu <span>"L’inconnu.e du campus"&nbsp;!</span>
      </div>
      <div class="first-start-disclaimer">
        Tu as trouvé un mystérieux Chromebook abandonné dans une salle de
        classe. Ce Chromebook n'a pas été laissé ici par hasard&nbsp;!<br />
        <br />
        Le Club d'Enquête du Campus (C.E.C) l'a spécialement paramétré dans le
        but de tester tes talents de détective.<br />
        <br />
        Le défi débute sur le navigateur Google Chrome où une page est
        verrouillée. Navigue dans les différentes applications de ce Chromebook
        pour trouver des indices et déverrouiller la page.<br />
        <br />
        Bonne chance&nbsp;!
      </div>
      <div class="first-start-continue" @click="requestClose">continuer</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FirstStartNotification",
  props: {
    isMobile: Boolean,
    isPortrait: Boolean,
  },
  methods: {
    requestClose: function () {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.first-start-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000aa;
  z-index: 30;
  display: grid;
  grid-template-columns: 25% 1fr 25%;
  grid-template-rows: 1fr auto 1fr;
}

.mobile .first-start-overlay {
  grid-template-columns: 5% 1fr 5%;
}

.first-start-container {
  background: #ffffff;
  border: 1.5em solid #2376e5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4.5em;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 5%;
}

.first-start-logo {
  width: 30%;
}

.first-start-title {
  font-family: Open Sans;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: center;
  color: #2376e5;
  margin-bottom: 3%;
}

.first-start-title > span {
  white-space: nowrap;
}

.first-start-disclaimer {
  font-family: Open Sans;
  font-size: 1.5em;
  font-weight: 800;
  line-height: 1.3em;
  letter-spacing: 0em;
  text-align: center;
  color: #fc421d;
}

.first-start-continue {
  background-color: #2376e5;
  font-family: Roboto;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  border-radius: 2.5em;
  text-transform: uppercase;
  padding: 1% 10%;
  margin-top: 5%;
  box-shadow: 0px 4.81208px 4.81208px rgba(0, 0, 0, 0.25);
  user-select: none;
  cursor: pointer;
}

.first-start-continue:hover {
  background-color: #ffffff;
  color: #2376e5;
}
</style>

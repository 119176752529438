import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import Vuex from 'vuex'
import store from "./store";

Vue.use(Vuex)
Vue.use(VueEasytable);

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
